import { headerHeight } from "../utils/constants"

export const colors = {
  text: "#515b6a",
  pink: "#ed2c88",
  pinkAction: "#CB126B",
  background: "#fff",
  blue: "#2875ea",
  blueAction: "#235DB5",
  bgGray: "#eff1f2",
  lightGray: "#dee5e8",
  darkGray: "#515b6a",
}

// in pixels
export const breakpoints = {
  tablet: 768,
  desktop: 1200,
}

export const siteMaxWidth = 1600

// mobile - up to 768
// tablet - 768-1200
// desktop - 1200-1600

const theme = {
  breakpoints: Object.values(breakpoints).map(px => `${px / 16}em`),
  colors,
  text: {
    heading: {
      fontWeight: 600,
      m: 0,
    },
    intro: {
      fontSize: ["1.8rem", "2.4rem", "3rem"],
      lineHeight: 1.5,
      fontWeight: "normal",
    },
  },
  styles: {
    root: {
      pt: [`${headerHeight.mobile}px`, 0],
      fontFamily: "Montserrat",
      fontSize: ["1.4rem", null, "1.6rem"],
      lineHeight: [1.5, null, 1.75],
      "--min-width": [0].concat(Object.values(breakpoints).map(px => px / 10)),
      "--max-width": Object.values(breakpoints)
        .map(px => px / 10)
        .concat(siteMaxWidth / 10),
    },
    h1: {
      variant: "text.heading",
      "--min-font-size": [2.6, 3.2, 3.8],
      "--max-font-size": [2.6, 3.8, 5],
      lineHeight: [1.38, 1.29, 1.2],
      mb: ["2.8rem", "3rem"],
      em: {
        fontStyle: "normal",
        color: "pink",
      },
    },
    h2: {
      variant: "text.heading",
    },
    h3: {
      variant: "text.heading",
    },
    h4: {
      variant: "text.heading",
    },
    p: {
      mt: 0,
      //mb: ["2.6rem", "3.2rem", "4rem"],
      mb: '2rem'
    },
    a: {
      color: "text",
      "&:hover": {
        color: "blue",
      },
      "&:active": {
        color: "blueAction",
      },
    },
  },
}

export default theme
